import React from "react";

import { PrivacyText } from "../components/PrivacyText";
import { PrivacyTextFr } from "../components/PrivacyTextFr";
import { PrivacyTextEs } from "../components/PrivacyTextEs";
import { Footer } from "../components/Footer";
import { useTranslation } from "react-i18next";
import Navbar from "../components/Navbar";

function Privacy() {
  const { i18n } = useTranslation();
  let lang = i18n.language;

  return (
    <div style={{ cursor: "auto" }}>
      <Navbar />

      {lang === "fr" && <PrivacyTextFr />}
      {lang === "en" && <PrivacyText />}
      {lang === "es" && <PrivacyTextEs />}
      <Footer />
    </div>
  );
}

export default Privacy;
