import React from "react";
import Home from "./pages/Home";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Finder from "./pages/Finder";
import ForYou from "./pages/ForYou";
import { Route, Routes } from "react-router-dom";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationES from "./locales/es/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  es: {
    translation: translationES,
  },
};

const userLanguage = navigator.language || navigator.userLanguage;
const initialLanguage = userLanguage.substring(0, 2);

i18n.use(initReactI18next).init({
  resources,
  lng: initialLanguage,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/finder" element={<Finder />} />
      <Route path="/foryou" element={<ForYou />} />
    </Routes>
  );
};

export default App;
