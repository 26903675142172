import React from "react";
import { ListItems } from "./ListItems";

import { HashLink as Link } from "react-router-hash-link";
import Comparator from "./Comparator";
import { DownloadsLogosSoon } from "./DownloadLogosSoon";
import { useTranslation } from "react-i18next";
export const Generic = (props) => {
  const { t } = useTranslation();
  let id = props.id;
  let side = props.side;

  const MyButton = () => {
    return (
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          paddingTop: 40,
          paddingBottom: 40,
        }}
      >
        <Link to="/finder" className="btn btn-custom btn-lg">
          {t("SearchColor")}
        </Link>
      </div>
    );
  };
  const Explanations = () => {
    let items =
      id === "Makeup" || id === "Hair" || id === "Clothing"
        ? [1, 2]
        : id === "Header"
        ? [1, 2, 3]
        : [];
    return (
      <div
        className="col-xs-12 col-md-6"
        style={{
          marginTop: 20, // id === "Header" && props.device.width < 900 ? 20 : 180,
        }}
      >
        <div className="about-text">
          <h2 className="headerInfo">{t(id + "h")}</h2>
          <p className={id === "Capture" ? "list_info" : ""}>{t(id + "sh")}</p>
          <h3 className="subHeaderInfo">{t(id + "h2")}</h3>
          {id === "Makeup" && (
            <div className="list-style">
              <div className="col-lg-6 col-sm-6 col-xs-12 list_info">
                <ul>
                  {items.map((d, i) => (
                    <li key={`${d}-${i}`}>{t(id + "LL" + i)}</li>
                  ))}
                </ul>
              </div>
              <div className="col-lg-6 col-sm-6 col-xs-12 list_info">
                <ul>
                  {items.map((d, i) => (
                    <li key={`${d}-${i}`}>{t(id + "LLB" + i)}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          <div className="list-style">
            <div className="  col-xs-12 list_info">
              <ul>
                {items.map((d, i) => (
                  <li key={`${d}-${i}`}>{t(id + "L" + i)}</li>
                ))}
              </ul>
            </div>
          </div>
          {id === "Header" && (
            <DownloadsLogosSoon device={props.device} text={t("Coming Soon")} />
          )}
        </div>

        {id === "Clothing" && <MyButton />}
      </div>
    );
  };
  let idAux = id === "Header" ? "Clothing" : id === "Clothing" ? "Header" : id;
  return (
    <div id={id}>
      <div className="container" style={{ marginBottom: 100 }}>
        <div className="row">
          {side === "r" && (
            <>
              {id === "Makeup" && <Comparator />}
              {id !== "Makeup" && (
                <ListItems device={props.device} id={idAux} />
              )}
              {Explanations()}
            </>
          )}
          {side === "l" && (
            <>
              {Explanations()}
              {id === "Makeup" && <Comparator />}
              {id !== "Makeup" && (
                <ListItems device={props.device} id={idAux} />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
