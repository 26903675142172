import React from "react";
//import { Navigation } from "../components/navigation";
import Navbar from "../components/Navbar";
import { TermsText } from "../components/TermsText";
import { TermsTextEs } from "../components/TermsTextEs";
import { TermsTextFr } from "../components/TermsTextFr";
import { Footer } from "../components/Footer";
import { useTranslation } from "react-i18next";

function Terms() {
  const { i18n } = useTranslation();

  let lang = i18n.language;

  return (
    <div style={{ cursor: "auto" }}>
      {/*<Navigation />*/}
      {<Navbar />}
      {lang === "fr" && <TermsTextFr />}
      {lang === "en" && <TermsText />}
      {lang === "es" && <TermsTextEs />}

      <Footer />
    </div>
  );
}

export default Terms;
