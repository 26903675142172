import React, { useState, useEffect } from "react";
//import { Navigation } from "../components/navigation";
import { Features } from "../components/features";
/*import { About } from "../components/about";
import { Services } from "../components/services";
import { Gallery } from "../components/gallery";
import { Testimonials } from "../components/testimonials";*/
import { Footer } from "../components/Footer";
import { Generic } from "../components/Generic";

import { Contact } from "../components/contact";
import JsonData from "../data/data.json";
import Navbar from "../components/Navbar";

const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [deviceInfo, setDeviceInfo] = useState("");
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  useEffect(() => {
    const handleResize = () => {
      const userAgent = navigator.userAgent;
      const width = window.innerWidth;
      const height = window.innerHeight;
      let deviceType = "Computer";
      if (/Android/i.test(userAgent)) {
        deviceType = "Android";
      } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        deviceType = "iPhone";
      }
      setDeviceInfo({ device: deviceType, height: height, width: width });
    };
    setLandingPageData(JsonData);
    const debouncedHandleResize = debounce(handleResize, 300);

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);
  let small = window.innerWidth < 992 ? true : false;
  return (
    <div style={{ cursor: "auto" }}>
      <Navbar />

      <header id="header">
        <div className="intro" style={{ marginTop: "100px" }}>
          <div className="overlayH">
            <Generic
              id={"Header"}
              side={"l"}
              data={landingPageData.Makeup}
              device={deviceInfo}
            />
          </div>
        </div>
      </header>
      <Generic
        id={"Clothing"}
        side={small ? "l" : "r"}
        data={landingPageData.Makeup}
        device={deviceInfo}
      />

      <Generic
        id={"Hair"}
        side={"l"}
        data={landingPageData.Makeup}
        device={deviceInfo}
      />

      <Generic
        id={"Makeup"}
        side={small ? "l" : "r"}
        data={landingPageData.Makeup}
        device={deviceInfo}
      />

      <div className="intro">
        <Generic
          id={"Capture"}
          side={"l"}
          data={landingPageData.Makeup}
          device={deviceInfo}
        />
      </div>

      <Features data={landingPageData.Features} />

      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
/*<HairColorList id="color" />
      <HairColorList id="hairDo" />
    <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Gallery data={landingPageData.Gallery} />
    <Testimonials data={landingPageData.Testimonials} />*/
