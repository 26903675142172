import { useState } from "react";
import { send } from "emailjs-com";
import React from "react";
import { useTranslation } from "react-i18next";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const { t } = useTranslation();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    const contact = {
      from_name: name,
      from_mail: email,
      message: message,
    };

    e.preventDefault();

    send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      contact,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
      .then((response) => {
        setIsFormSubmitted(true);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>{t("get_in_touch")}</h2>
                {!isFormSubmitted && <p>{t("FillOut")}</p>}
              </div>
              {!isFormSubmitted ? (
                <form
                  name="sentMessage"
                  validate="true"
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder={t("Name")}
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder={t("Email")}
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder={t("Message")}
                      required
                      onChange={handleChange}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <button type="submit" className="btn btn-custom btn-lg">
                      {t("SendMessage")}
                    </button>
                  </div>
                </form>
              ) : (
                <div>
                  <h3 className="head-text">{t("Thanks")}</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
