import React from "react";
import ProductItem from "./ProductItem";

const ProductList = ({ products, id }) => {
  return (
    <div className="product-list-container">
      {products.map((product, index) => (
        <ProductItem key={product[1] + index} product={product} id={id} />
      ))}

      {/*products.length > 1 &&
        products[1].map((product, index) => (
          <ProductItem key={product[1] + index} product={product} />
        ))*/}
    </div>
  );
};

export default ProductList;
