import React, { useState, useEffect } from "react";
//import { Navigation } from "../components/navigation";
import { Footer } from "../components/Footer";
import { useTranslation } from "react-i18next";
import ProductList from "../components/ProductList";
import { FileUpload } from "../components/FileUpload";
import { getProducts } from "../utils/getProducts";
import tinycolor from "tinycolor2";
import Navbar from "../components/Navbar";

function Finder() {
  const [currentColor, setCurrentColor] = useState("#ffffff");
  const [selectedColor, setSelectedColor] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [products, setProducts] = useState();
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [showCursor, setShowCursor] = useState(false);
  const [dim, setDim] = useState(400);
  const [deviceInfo, setDeviceInfo] = useState("");
  const { t } = useTranslation();

  //If something goes wrong maybe this work https://stackblitz.com/edit/stackblitz-starters-7dwufu?file=src%2FApp.js
  useEffect(() => {
    const userAgent = navigator.userAgent;
    const width = window.innerWidth;
    const height = window.innerHeight;
    let deviceType = "Computer";
    if (/Android/i.test(userAgent)) {
      deviceType = "Android";
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      deviceType = "iPhone";
    }
    setDeviceInfo({ device: deviceType, height: height, width: width });
  }, []);

  useEffect(() => {
    let aux = Math.min(window.innerWidth, 400);
    setDim(aux);

    if (imageDimensions.height > 0) {
      const aspectRatio = imageDimensions.width / imageDimensions.height;

      let iWidth = aux;
      let iHeight = aux;
      if (imageDimensions.width > imageDimensions.height) {
        iHeight = Math.trunc(aux / aspectRatio);
      } else {
        iWidth = Math.trunc(aux * aspectRatio);
      }

      setImageDimensions({ width: iWidth, height: iHeight });
    }
    // eslint-disable-next-line
  }, [window.innerWidth]);
  const isTooDark = (colorInput) => {
    let color = tinycolor(colorInput).toHexString();
    color = color.substring(1); // strip #
    let rgb = parseInt(color, 16); // convert rrggbb to decimal
    let r = (rgb >> 16) & 0xff; // extract red
    let g = (rgb >> 8) & 0xff; // extract green
    let b = (rgb >> 0) & 0xff; // extract blue

    let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    return luma < 140;
  };

  const handleImageLoad = (e) => {
    const img = e.target;

    const aspectRatio = img.width / img.height; //img.width === 0 ? 1 : img.width / img.height;

    let iWidth = dim;
    let iHeight = dim;
    if (img.width > img.height) {
      iHeight = Math.trunc(dim / aspectRatio);
    } else {
      iWidth = Math.trunc(dim * aspectRatio);
    }

    setImageDimensions({ width: iWidth, height: iHeight });
  };

  useEffect(() => {
    let cur = showCursor ? "pointer" : "none";

    return () => {
      document.body.style.cursor = cur; // Restaura el cursor predeterminado al desmontar el componente
    };
  }, [showCursor]);
  const handleMouseMove = (e, orig = 0) => {
    //const image = document.getElementById("mainImg");
    //const rect = image.getBoundingClientRect();
    //   window.scrollTo({ top: 0, behavior: "smooth" });
    const square = e.currentTarget;
    const x = e.nativeEvent.offsetX / square.clientWidth;
    const y = e.nativeEvent.offsetY / square.clientHeight;

    const canvas = document.createElement("canvas");
    canvas.width = square.clientWidth;
    canvas.height = square.clientHeight;
    const context = canvas.getContext("2d");

    const img = square.querySelector("img");

    const drawWidth = img.width;
    const drawHeight = img.height;
    context.drawImage(img, 0, 0, drawWidth, drawHeight);

    const pixel = context.getImageData(
      x * drawWidth,
      y * drawHeight,
      1,
      1
    ).data;

    const color = `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`;
    setCurrentColor(color);
    setPosition({ x: e.clientX, y: e.clientY });

    if (orig !== 0) {
      setSelectedColor(color);
      let data = getProducts(color);

      setProducts(data);
    }
  };
  const preventDragHandler = (e) => {
    e.preventDefault();
  };
  const handleClick = (e) => {
    setShowCursor(true);
    handleMouseMove(e, 1);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (readerEvent) => {
        const imageUrl1 = readerEvent.target.result;
        setImageUrl(imageUrl1);
        setSelectedColor("");
      };

      reader.readAsDataURL(file);
    }
  };
  const handleMouseEnter = () => {
    if (imageUrl !== "") {
      setShowCursor(true);
      /*   if (deviceInfo.deviceType === "Computer") {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }*/
    } else {
      setShowCursor(false);
    }
  };

  const handleMouseLeave = () => {
    setShowCursor(false);
  };
  const explanation = (top = true) => {
    return (
      <div
        className="section-title"
        style={{
          paddingRight: 15,
          paddingLeft: 15,
          display: "flex",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <article className="arti">
          <h1 className="headerInfo">{t("finderH")}</h1>
          {top && <p>{t("finderP")}</p>}
        </article>
      </div>
    );
  };
  const explanation2 = () => {
    return (
      <div
        className="section-title"
        style={{
          paddingRight: 15,
          paddingLeft: 15,
          display: "flex",
          alignItems: "center",
          marginBottom: 20,
          fontSize: 16,
        }}
      >
        <article className="arti">
          <p style={{ fontSize: 16 }}>{t("finderI")}</p>
        </article>
      </div>
    );
  };
  /* const getImagePosition = () => {
    const image = document.getElementById("mainImg");
    const rect = image.getBoundingClientRect();
    console.log("Top:", rect.top);

  };*/
  const customCursor = () => {
    const image = document.getElementById("mainImg");
    const rect = image.getBoundingClientRect();
    console.log({ position: position, "top image": rect.top });
    return (
      <div
        style={{
          position: "absolute",
          left: `${position.x - 30}px`,
          top: `${position.y - 30}px`,
          backgroundColor: "transparent",
          width: "60px",
          height: "60px",
          borderRadius: "50%",
          pointerEvents: "none",
          zIndex: 10,
          borderWidth: "4px",
          borderColor: "white",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: `0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1), 0 16px 24px rgba(0, 0, 0, 0.1), inset 0 0 0 10px ${currentColor}`,
          cursor: "none",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <i
            className="fa fa-crosshairs"
            style={{
              fontSize: "22px",
              color: isTooDark(currentColor) ? "white" : "#333333",
            }}
          ></i>
        </div>
      </div>
    );
  };
  return (
    <div
      style={{
        height: "100%",
        paddingTop: "100px",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        cursor: "auto",
        //  cursor: "pointer",
      }}
    >
      <div style={{ flex: 1 }}>
        {<Navbar />}

        {explanation(imageUrl !== "" ? false : true)}

        <div
          style={{
            justifyContent: "center",
            width: "100%",
            display: "flex",
            marginTop: imageDimensions.height === 0 ? "50px" : "20px",
          }}
        >
          <div
            style={{
              height:
                imageDimensions.height === 0 ? dim : imageDimensions.height,
              width: imageDimensions.height === 0 ? dim : imageDimensions.width,
            }}
          >
            <div
              style={{
                height:
                  imageDimensions.height === 0 ? dim : imageDimensions.height,
                width:
                  imageDimensions.height === 0 ? dim : imageDimensions.width,
                backgroundColor:
                  imageDimensions.height === 0 ? "F6F6F6" : "white",
                // borderRadius: "10px",
                border: imageDimensions.height === 0 ? "2px solid #F1EBE6" : "",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {imageUrl === "" && (
                <FileUpload
                  text={t("Select")}
                  handleFileChange={handleFileChange}
                  type="Initial"
                />
              )}
              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
                onClick={handleClick}
              >
                <img
                  id="mainImg"
                  src={imageUrl}
                  onLoad={handleImageLoad}
                  onDragStart={preventDragHandler}
                  style={{
                    touchAction: "none",
                    userSelect: "none",
                    maxWidth: "100%",
                    height: "auto",
                    /* height: imageDimensions.height,
                  width: imageDimensions.width,*/
                    border: "1px solid silver",
                    //  objectFit: "contain",
                  }}
                  alt={imageUrl !== "" ? "Selected" : ""}
                />

                {(showCursor || selectedColor !== "") &&
                  imageUrl !== "" &&
                  // deviceInfo.device === "Computer" &&
                  customCursor()}
              </div>
            </div>
          </div>
        </div>
        {imageUrl !== "" && (
          <>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
                display: "flex",
                borderWidth: "0px",
                marginTop: "10px",
              }}
            >
              <FileUpload
                text={t("Change")}
                handleFileChange={handleFileChange}
                type="New"
              />
            </div>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
                display: "flex",
                borderWidth: "0px",
                marginTop: "10px",
              }}
            >
              {imageUrl !== "" && explanation2()}
            </div>
          </>
        )}

        {selectedColor !== "" && imageUrl !== "" && (
          <>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
                display: "flex",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  width: Math.min(window.innerWidth, dim),
                  height: "50px",
                  backgroundColor: selectedColor,
                }}
              ></div>
            </div>
            <div>
              <ProductList products={products} />
            </div>
          </>
        )}
      </div>

      {<Footer device={deviceInfo} />}
    </div>
  );
}

export default Finder;
